import { createStore } from 'vuex'
import authModule from './auth';
import accountModel from './account';

import { AuthStateInterface } from './auth/state';
import { AccountStateInterface } from './account/state';

export interface StateInterface {
  auth: AuthStateInterface,
  account: AccountStateInterface
}

export default createStore<StateInterface>({
  modules: {
    auth: authModule,
    account: accountModel,
  }
})
