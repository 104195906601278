import { MutationTree } from 'vuex';
import { AccountStateInterface } from './state';
import { IaccountResponse } from '@/interfaces/interface_account';
import IaccountLog from '../../interfaces/interface_account_log';

const mutation: MutationTree<AccountStateInterface> = {
    isLoading(state: AccountStateInterface, val: boolean){
        state.isLoading = val;
    },

    setSelectAccount(state: AccountStateInterface, val: IaccountResponse){
        state.selectAccount = val;
    },

    setAccounts(state: AccountStateInterface, val: IaccountResponse[]){
        state.accounts = val
    },

    setAccountsLog(state: AccountStateInterface, val: IaccountLog[]){
        state.accountsLog = val
    }
}

export default mutation;