import { ActionTree } from 'vuex';

import {AccountStateInterface} from './state';
import { StateInterface } from '../index';

import serviceRequest from '@/helpers/service_request';
import IAccount, { IaccountResponse } from '@/interfaces/interface_account';

import IYappy, { IYappyResponse } from '@/interfaces/interface_yappy';

const actions: ActionTree<AccountStateInterface, StateInterface> = {

    async listAccountLog({commit}, idAccount: number){
        commit('isLoading', true);
        await serviceRequest(
            `accountLog/show/${idAccount}`, 
            {
                method: 'GET', 
                token: localStorage.getItem('token') ?? ""
            },
        ).then((resp) => {
            const data: IaccountResponse[] = resp.data;
            commit('setAccountsLog', data);
        }).catch(()=> {
            commit('setAccountsLog', []);
            // throw response.data;
        }).finally(()=> {
            commit('isLoading', false);
        })
    },

    async listUserFavorite({commit}){
        commit('isLoading', true);
        await serviceRequest(
            'user', 
            {
                method: 'GET', 
                token: localStorage.getItem('token') ?? ""
            },
        ).then((resp) => {
            const data: IaccountResponse[] = resp.data;
            commit('setAccounts', data);
            if(data.length > 0){
                commit('setSelectAccount', data[0]);
            }
        }).catch(()=> {
            commit('setAccounts', []);
            // throw response.data;
        }).finally(()=> {
            commit('isLoading', false);
        })
    },

 /*    async addUserFavorite({commit}, data: IAccount){
        commit('isLoading', true);
        await serviceRequest(
            'user', 
            {
                method: 'POST', 
                data: data,
                token: localStorage.getItem('token') ?? ""
            },
        ).then((resp) => {
            return resp.data;
        }).catch(({response})=> {
            throw response.data;
        }).finally(()=> {
            commit('isLoading', false);
        })
    }, */
    async addUserFavorite({commit}, data: IAccount){
        commit('isLoading', true);

        try {

            const response = await serviceRequest('user', {
                method: 'POST',
                data: data,
                token: localStorage.getItem('token') ?? '',
            });

            const responseData = response.data; 

            return responseData;
        } catch (error) {
            return error;
        } finally {
            commit('isLoading', false);
        }
    },

    async selectUser({commit}, data: IaccountResponse) {
        commit('setSelectAccount', data);
    },

    async payYappi({commit}, data: IYappy) {
        commit('isLoading', true);
        console.log(data);
        await serviceRequest(
            'account/yappi', 
            {
                method: 'POST', 
                data: data,
                token: localStorage.getItem('token') ?? ""
            },
        ).then((resp) => {
            const data: IYappyResponse = resp.data;
            console.log(data);
            if(data.success){
                window.location.href = data.url;
            }else{
                alert("Error");
            }
        }).catch(()=> {
            alert("Error");
            // commit('setAccounts', []);
            // throw response.data;
        }).finally(()=> {
            commit('isLoading', false);
        })
        
    },


}

export default actions;