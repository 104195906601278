import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const islogin = (
    to: RouteLocationNormalized, 
    from: RouteLocationNormalized, 
    next: NavigationGuardNext,
) => {
    if (localStorage.getItem('token')){
        next({name:"home"})
    } else {
        next()
    }
}

export const isNotLogin = (
    to: RouteLocationNormalized, 
    from: RouteLocationNormalized, 
    next: NavigationGuardNext,
) => {
    if (localStorage.getItem('token')){
        next()
    } else {
        next({name:"login"})
    }
}
