import { createRouter, RouteRecordRaw, createWebHistory } from 'vue-router'
import { islogin } from '@/middlewares/user_login';
import { isNotLogin } from '../middlewares/user_login';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/success',
    name: 'success',
    component: () => import(/* webpackChunkName */ '@/views/SuccessView.vue'),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName */ '@/views/ErrorView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: islogin,
    component: () => import(/* webpackChunkName */ '@/views/LoginView.vue'),
  },
  {
    path: '/register',
    name: 'register',
    // beforeEnter: islogin,
    component: () => import(/* webpackChunkName */ '@/views/RegisterView.vue'),
  },
  {
    path: '/recharge/:idAccount',
    name: 'recharge',
    beforeEnter: isNotLogin,
    component: () => import(/* webpackChunkName */ '@/views/RechargeView.vue'),
    props: true,
  },
  {
    path: '/',
    name: 'home',
    beforeEnter: isNotLogin,
    component: () => import(/* webpackChunkName */ '@/views/HomeView.vue'),
  },
  {
    path: '/newUser',
    name: 'newUser',
    beforeEnter: isNotLogin,
    component: () => import(/* webpackChunkName */ '@/views/NewUserView.vue'),
  },
  {
    path: '/tOf',
    name: 'tOf',
    component: () => import(/* webpackChunkName */ '@/views/ToFView.vue'),
  },
  {
    path: '/payACH',
    name: 'payACH',
    component: () => import(/* webpackChunkName */ '@/views/PayACH.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName */ '@/views/ForgotPassword.vue'),
  },
  {
    path: '/change_password',
    name: 'change-password',
    component: () => import(/* webpackChunkName */ '@/views/ChangePassword.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
