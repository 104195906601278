// import IUserResponse from "@/interfaces/interface_user";

export interface AuthStateInterface {
    // isAlert: boolean;
    // msgAlert: string;
    isLoading: boolean;
    // user: IUserResponse|null;
}

function state(): AuthStateInterface {
    return {
        // isAlert: false,
        // msgAlert: "login.alert.user",
        isLoading: false,
        // user: null,
    }
}

export default state;