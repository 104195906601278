import axios from "axios";

async function serviceRequest(
    path: string, 
    {
        method, 
        data,
        token,
        params
    }: 
    {
        method: string, 
        data?:  unknown,
        token?: string, 
        params?: {[name:string]:string}
    }) {
        // const url = `http://localhost:8000/${path}`
        const url = `https://quepedimoscr.co/${path}`

        const headers: { [name: string]: string } = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
        
        if(token != null && token != undefined) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        
        const resp = await axios.request(
            {
                method,
                url,
                headers,
                params,
                data
            }
        )

        return resp;
}

export default serviceRequest;