import { MutationTree } from 'vuex';
import { AuthStateInterface } from './state';
// import IUserResponse from '@/interfaces/interface_user';

const mutation: MutationTree<AuthStateInterface> = {
    isLoading(state: AuthStateInterface, val: boolean){
        state.isLoading = val;
    },
    // setUder(state: AuthStateInterface, val: IUserResponse) {
    //     state.user = val;
    // },
}

export default mutation;