import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './translator'
import { createVuetify } from 'vuetify'
import 'vuetify/styles'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'


import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
})

createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .use(vuetify)
    .mount('#app')
