import { IaccountResponse } from '@/interfaces/interface_account';
import IaccountLog from '@/interfaces/interface_account_log';
export interface AccountStateInterface {
    isLoading: boolean;
    selectAccount: IaccountResponse|null;
    accounts: IaccountResponse[];
    accountsLog: IaccountLog[];
    schools : { [key: number]: string };
}

function state(): AccountStateInterface{
    return {
        isLoading: false,
        selectAccount: null,
        accounts: [],
        accountsLog: [],
        schools: {
            5736: "Balboa Academy High School-BAHS",
            5731: "Balboa Academy Middle School- BA",
            5699: "Instituto Cultural-IC",
            5680: "The Scala School Panamá Este-SSPE",
            5679: "The Scala School brisas del golf Arraijan-SSBA",
            5678: "The Scala Vacamonte-SSV",
            5677: "The Scala School Penonomé-SSP",
            5671: "The Oxford School Costa Verde-TOSCV",
            5670: "The Oxford School Azuero-TOSA",
            5669: "The Oxford School Santiago-TOSS",
            5626: "The Oxford School David-TOSD",
            5612: "Colegio Real Panamá- CRP",
            5427: "The Oxford School Paseo del Norte-TOSPN",
            5827: "Colegio Las Esclavas-CLE",
        },
    }
}

export default state;
