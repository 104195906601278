import { ActionTree } from 'vuex';

import { AuthStateInterface } from './state';
import { StateInterface } from '../index';

import serviceRequest from '@/helpers/service_request';
import IUser from '@/interfaces/interface_user';
import IAuth from '@/interfaces/interface_auth';
import router from '@/router/index';

const actions: ActionTree<AuthStateInterface, StateInterface> = {
    async login({commit}, data: IAuth){
        commit('isLoading', true);
        await serviceRequest(
            'login', 
            {
                method: 'POST', 
                data: data,
            },
        ).then((resp) => {
            const token:string = resp.data;
            localStorage.setItem('token', token);
            router.push({name: "home"});
        }).catch(({response})=> {
            localStorage.removeItem('idUser');
            throw response;
        })
        .finally(()=> {
            commit('isLoading', false);
        })
    },

    //Nuevo login para hacer la recarga por Yappy desde la ventana de registro
    async loginFromRegister({ commit }, data: IAuth) {
        commit('isLoading', true);
      
        try {
          const response = await serviceRequest('login', {
            method: 'POST',
            data: data,
          });
      
          const token: string = response.data;
          localStorage.setItem('token', token);
            
          return token;
        } catch (error) {
          localStorage.removeItem('idUser');
      
          throw error;
        } finally {
          commit('isLoading', false);
        }
    },
    async signOff({commit}){
        commit('setUder', null);
        localStorage.removeItem('token');
        router.push({name: "login"});
    },
   /*  async register({commit}, data: IUser){
        commit('isLoading', true);
        await serviceRequest(
            'register', 
            {
                method: 'POST', 
                data: data,
            },
        ).then(() => {
            router.push({name: "home"});
        }).catch(({response})=> {
            throw response;
        })
        .finally(()=> {
            commit('isLoading', false);
        })
    } */
    async register({commit}, data: IUser){
        commit('isLoading', true);

        try {
            const response = await serviceRequest('register', {
                method: 'POST',
                data: data,
            });

            const responseData = response.data; 

            return responseData;
        } catch (error) {
           
            return error;
        } finally {
            commit('isLoading', false);
        }
    },
    async forgotPassword({commit}, email: string){
        commit('isLoading', true);

        try {
            const response = await serviceRequest('recoveryPassword', {
                method: 'POST',
                data: {
                    "userName": email,
                },
            });
            
            const responseData = response.data; 
            router.push({name: "change-password"});
            return responseData;
        } catch (error) {
           
            return error;
        } finally {
            commit('isLoading', false);
        }
    },

    async changePasswordRecovery({commit}, data: IUser){
        commit('isLoading', true);

        try {
            const response = await serviceRequest('changePasswordRecovery', {
                method: 'POST',
                data: data,
            });
            
            const responseData = response.data; 
            router.push({name: "login"});
            return responseData;
        } catch (error) {
           
            return error;
        } finally {
            commit('isLoading', false);
        }
    },
}

export default actions;