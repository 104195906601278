import { GetterTree } from 'vuex';
import { AccountStateInterface } from './state';
import { StateInterface } from '../index';


const getters: GetterTree<AccountStateInterface, StateInterface> = {
    someGetter( /* state */ ) {
        // return true;
    }
}



export default getters;